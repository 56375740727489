import VueGtag, { trackRouter } from "vue-gtag-next";
import type { CatalogCollection, TextileItemForCart } from "@/types/products";
import type { CartTextileItem } from "@/types/cart";

interface AnalyticsTextileItem {
	id: string;
	organization_textile_item_code: string;
	textile: {
		organization_name: string;
		organization_textile_code: string;
	};
	inventory?: {
		totalMeters: number;
	};
	// inventory_id: string;
	pricing: {
		adjusted_price: number;
	};
}

export function mapTextileItemToGAItem(textile_item: AnalyticsTextileItem, collection?: CatalogCollection) {
	try {
		return {
			item_id: textile_item.id,
			item_name: textile_item.organization_textile_item_code, // "Test color code"
			// affiliation: "Google Merchandise Store",
			affiliation: textile_item.textile.organization_name, // "Vrstex"
			// coupon: "SUMMER_FUN",
			// discount: 2.22,
			// index: 0,
			item_brand: textile_item.textile.organization_textile_code, // KW-3530D
			// item_category: textile_item.textiles_item?.textile.organization_textile_code, // KW-3530D
			// item_category2: "Adult",
			// item_category3: "Shirts",
			// item_category4: "Crew",
			// item_category5: "Short sleeve",
			item_list_id: collection ? `collection-${collection?.id}` : "",
			item_list_name: collection?.name || "",
			item_variant: "",
			// item_variant: textile_item.textiles_item?.textile.organization_textile_code, // KW-3530D
			location_id: "",
			price: textile_item.pricing.adjusted_price,
			quantity: 1, // textile_item.inventory?.totalMeters || 0,
		};
	} catch (err) {
		console.error("error mapping item event", err, textile_item, collection);
		return {};
	}
}

export function mapCartItemToGAItem(cartItem: TextileItemForCart) {
	try {
		const itemData = {
			...cartItem,
			id: cartItem.textile_item_id,
			textile: {
				organization_name: cartItem.organization_name || "",
				organization_textile_code: cartItem.organization_textile_code,
			},
			pricing: { adjusted_price: cartItem.unit_price },
		};
		const item = mapTextileItemToGAItem(itemData);
		item.quantity = cartItem.meter_quantity;
		item.item_variant = cartItem.sample ? "sample" : "";
		item.location_id = cartItem.warehouse_id;
		return item;
	} catch (err) {
		console.error("error mapping item event", err, cartItem);
		return {};
	}
}

export function mapCartToGAEvent(cartProducts: CartTextileItem[][], cartSamples: CartTextileItem[]) {
	try {
		let value = 0;
		const items = [];
		for (const warehouseItems of cartProducts || []) {
			for (const textileItems of warehouseItems) {
				for (const cartItem of textileItems as any as TextileItemForCart[]) {
					const item = mapCartItemToGAItem(cartItem);
					value += (item.price || 0) * (item.quantity || 0);
					items.push(item);
				}
			}
		}
		for (const cartItem of cartSamples || []) {
			const item = mapCartItemToGAItem(cartItem as any as TextileItemForCart);
			value += (item.price || 0) * (item.quantity || 0);
			items.push(item);
		}
		return {
			currency: "USD",
			value,
			items,
		};
	} catch (err) {
		console.error("error mapping cart event", err, cartProducts, cartSamples);
		return {};
	}
}

export default defineNuxtPlugin(nuxtApp => {
	const { gtagId } = useRuntimeConfig().public;

	const url = useRequestURL();
	const platformStore = usePlatformStore();
	const { user, userOrganization } = useUserStore();

	const settings = platformStore.platform?.platform_settings[0];

	if (!gtagId) {
		return;
	}

	nuxtApp.vueApp.use(VueGtag, {
		property: {
			id: gtagId,
			params: {
				// platform data
				platform_id: settings?.id,
				tengiva_platform_code: settings?.tengiva_platform_code,
				service_subscription_id: settings?.service_subscription_id,
				platform_variant: settings?.platform_variant,
				platform_template_id: settings?.platform_template_id,
				platform_name: settings?.platform_config.name,
				platform_url: url.hostname,

				// user data
				user_id: user?.id,
				user_language: user?.language,
				tengiva_user_code: user?.tengiva_user_code,
				user_organization_id: user?.organization_id,
				user_title: user?.title,
				tengiva_organization_code: userOrganization?.tengiva_organization_code,
			},
		},
	});

	// TODO: consent

	trackRouter(useRouter());
});
